import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { login } from '../../store/session';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useDocumentTitle } from '../Common/useMeta';
import { useQuery } from '../../utils/useQuery';

import { ReactComponent as NearIcon } from '../../img/icons/icon_nm.svg'


const LoginFormPage = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const sessionUser = useSelector(state => state.session.user);
    const nearConnection = useSelector(state => state.near.api);
    const nearWallet = useSelector(state => state.near.wallet);
    const nearConfig = useSelector(state => state.near.config);
    const [ credential, setCredential ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ inputErrors, setInputErrors ] = useState([]);
    const [ processing, setProcessing ] = useState(false);
    const [ walletProcessing, setWalletProcessing ] = useState(false);
    const [ redirectOnConnect, setRedirectOnConnect] = useState(null);
    const { trackPageView } = useMatomo();

    useDocumentTitle('Sign In');
  
    // Track page view
    useEffect(() => {
      trackPageView();
    }, []);    

    useEffect(() => {
        if (query && query.get('redirect') ) {            
            setRedirectOnConnect(query.get('redirect'));
        }
    }, [query]);

    useEffect(async() => {
        if (nearWallet?.isSignedIn() && query?.get('account_id') && query?.get('public_key')) {      
            // Just logged in with wallet
            setInputErrors([]);
            setWalletProcessing(true);

            console.log(nearConnection.config.networkId, query?.get('account_id'));
            const keyPair = await nearConnection.config.keyStore.getKey(nearConnection.config.networkId, query?.get('account_id'));
            const { signature } = keyPair.sign(Buffer.from(query?.get('account_id')+"||Tamago"));            

            return dispatch(login({ credential: query?.get('account_id'), password: keyPair.publicKey.toString()+"::"+Buffer.from(signature).toString('base64'), method: 'nearWallet' }))
                .catch((res) => {
                    setWalletProcessing(false);
                    if (res.data && res.data.errors) setInputErrors(res.data.errors);
                    else setInputErrors(['Error signing in! Please reload and try again.']);
                });
        }
    }, [nearWallet, query])

    if (sessionUser) {
        if (sessionUser.accountStatus == 'verify') {
            return (<Redirect to='/verify' />);
        }
        if (redirectOnConnect) {
            return (<Redirect to={redirectOnConnect} />);
        }
        return (<Redirect to='/' />)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setInputErrors([]);
        setProcessing(true);

        return dispatch(login({ credential, password, method: 'email' }))
            .catch((res) => {
                setProcessing(false);
                if (res.data && res.data.errors) setInputErrors(res.data.errors);
                else setInputErrors(['Error signing in! Please reload and try again.']);
            });
    };

    const handleWalletLogin = async (e) => {
        e.preventDefault();
        setInputErrors([]);
        setProcessing(true);

        nearWallet.requestSignIn(
            nearConfig.logIntoAccount, 
            'Tamago',
            null, 
            null
        );

    };

    return (
        <div className="w-full mx-auto absolute min-h-full pb-32 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">
            <div className="p-1 mb-4 hidden">
                <h1 className='text-2xl m-2 text-center'>Welcome to <span className="font-bold tracking-widest">TAMAGO</span></h1>
                <h2 className='text-xl m-2 text-center'>A music streaming platform dedicated to artist</h2>
            </div>
            <h1 className="block text-2xl text-white my-2 text-center">Sign-in</h1>
            <form onSubmit={handleSubmit} className="px-8 py-6 w-5/6 sm:w-96 mb-4 shadow-lg rounded bg-white glow">                

                <div className="flex items-center justify-center text-white rounded border px-4 py-2 cursor-pointer w-52 mx-auto mt-3" 
                     style={{
                        background: 'linear-gradient(235deg, #423f9c 31%, #862359 69%)',                         
                        width: 215
                    }}
                    onClick={walletProcessing ? null : handleWalletLogin}
                >
                    <NearIcon height={20} className='mr-2' fill='#fff' /> <div className={'text-xl '+(walletProcessing?'dotty':'')}>{walletProcessing ? 'Signing In' : 'Sign-In with Wallet' }</div>
                </div>
                {
                    !walletProcessing && 
                    <>
                        <div className="m-8 border text-black text-center h-0 relative" style={{borderColor: '#ccc'}}>
                            <div className='absolute px-4' style={{marginTop: -11, marginLeft: -26, backgroundColor: 'rgb(242, 243, 251)', display: 'inline-block'}}>OR</div>
                        </div>

                        { inputErrors.length > 0 && 
                            <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
                                {inputErrors.map((error, idx) => <li key={idx}>{error}</li>)}
                            </ul>
                        }

                        <label className="block font-bold text-black mb-2">
                            Username or E-mail
                            <input
                                type='text'
                                placeholder='Enter your username or e-mail'
                                value={credential}
                                onChange={(e) => setCredential(e.target.value)}
                                required
                                className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
                                style={{background: '#1d50b322'}}
                            />
                        </label>
                        <label className="block font-bold text-black">
                            Password
                            <input
                                type='password'
                                placeholder='Enter your password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 " 
                                style={{background: '#1d50b322'}}
                            />
                        </label>
                        <Link to='/forgot-password' className='text-sm text-black text-right block'>Forgot password?</Link>
                        <div className='mt-2 flex justify-around flex-col sm:flex-row'>
                            <button type='submit' className={"sm:w-2/6 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded mt-4 mb-0 "+(processing?'dotty':'')}>{ processing ? 'Signing In' : 'Sign-In' }</button>
                        </div>
                    </>
                }
            </form> 
            <div className="p-1">                
                <Link to='/signup' className="text-mandarin font-large hover:font-bold">Create Profile</Link>
            </div>
            {
                /*
                <div className='text-center mt-4 absolute bottom-0'>
                    <span onClick={() => {dispatch(login({ credential: 'DemoUser', password: 'password' }))}} className="cursor-pointer py-2 px-4 my-4">Sign in as Demo User</span>
                </div>
                */
            }
        </div>
    );
}

export default LoginFormPage;
